import React from 'react'
import styles from './index.module.scss'
import BoxImageTitle from '../BoxImageTitle/BoxImageTitle'

interface ItemsProps {
  buttonText: string
  image: string
  link: string
  text: string
}

interface Props {
  children: JSX.Element
  title: string
  items: ItemsProps[]
}

export default function BoxImagenTituloList({ children, title, items }: Props) {
  if (!items || items.length === 0) {
    return
  }

  const itemList = items.map((item, index) => (
    <BoxImageTitle
      key={index}
      text={item.text}
      link={item.link}
      image={item.image}
      buttonText={item.buttonText}>
      {children}
    </BoxImageTitle>
  ))
  return (
    <div className={styles.boxContainer}>
      {title && <h3 className={styles.title_itemList}>{title}</h3>}
      <div className={styles.itemList}>{itemList}</div>
    </div>
  )
}
