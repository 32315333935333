import { t } from '../../i18n'
import route from '../../utils/route'
import BoxImageTitleList from '../BoxImageTitleList/BoxImageTitleList'
import styles from './cardTireModel.module.scss'
import React from 'react'

export interface ICardTireModelo {
  name: string
  slug: string
  urlimagen: string
}
interface Props {
  marca: string
  logoMarca: string
  neumaticos: ICardTireModelo[]
  title?: string
}
const CardTireModel: React.FC<Props> = ({
  marca,
  logoMarca,
  neumaticos,
  title,
}) => {
  let logoUrl
  try {
    logoUrl = require(`!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../assets/images/logo_marcas/${logoMarca}.svg`)
  } catch (e) {
    return null
  }

  const items = neumaticos.map((item) => ({
    text: `${marca} ${item.name}`,
    image: item.urlimagen,
    buttonText: t('ficha-marca.boton'),
    link: route('neumaticos.modelo', {
      marca: marca.toLowerCase(),
      modelo: item.slug.toLowerCase(),
    }),
  }))
  return (
    <BoxImageTitleList items={items} title={title}>
      <img src={logoUrl.default} className={styles.logo} alt={marca} />
    </BoxImageTitleList>
  )
}

export default CardTireModel
