import Neumatico from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../assets/neumatico-selector.png'
import React from 'react'
import { connect } from 'react-redux'
import { IAppState } from '../../context/context'
import { SelectorState } from '../../context/reducers/selectorReducer'
import { selectSelector } from '../../context/selectors/selectorSelectors'
import styles from './neumaticoMobileDefaultValues.module.scss'

interface Props {
  selectorState: SelectorState
}
const NeumaticoMobileDefaultValues = ({ selectorState }: Props) => {
  return (
    <div className={styles.neumatico_wrapper}>
      <img
        src={Neumatico}
        alt="Neumático genérico"
        className={styles.neumatico}
        width={450}
        height={133}
      />
      <span className={styles.anchoSpan}>
        {' '}
        {selectorState.ancho.default || 205} /
      </span>
      <span className={styles.serieSpan}>
        {' '}
        {selectorState.serie.default || 55}{' '}
      </span>
      <span className={styles.llantaSpan}>
        R {selectorState.llanta.default || 16}
      </span>
      <span className={styles.icSpan}>{selectorState.carga.default || 91}</span>
      <span className={styles.cvSpan}>
        {selectorState.velocidad.default || 'V'}
      </span>
    </div>
  )
}

export default connect((state: IAppState) => ({
  selectorState: selectSelector(state),
}))(NeumaticoMobileDefaultValues)
