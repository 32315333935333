import { graphql, navigate, PageProps } from 'gatsby'
import React, { useContext, useEffect } from 'react'
import { connect } from 'react-redux'
import SelectorContainer from '../../components/FunnelSelector/Entrada/SelectorContainer'
import SelectorForm from '../../components/FunnelSelector/Entrada/SelectorForm'
import Step, {
  findNext,
  InSkipInfo,
} from '../../components/FunnelSelector/SelectorSteps'
import Layout from '../../components/Layout'
import NeumaticoMobileDefaultValues from '../../components/NeumaticoMobileDefaultValues/NeumaticoMobileDefaultValues'
import ResponsiveSliderBanner from '../../components/ResponsiveSliderBanner/ResponsiveSliderBanner'
import Title from '../../components/Title'
import { MenuPrincipal } from '../../../specific/constants/menu'
import { reset } from '../../context/actions/selectorActions'
import AppContext, { IAppState } from '../../context/context'
import { selectSkipInfo } from '../../context/selectors/selectorSelectors'
import { useSelectedMenu } from '../../../specific/helpers/customHooks/useSelectedMenu'
import dataLayer from '../../helpers/functions/dataLayer'
import { locale, t } from '../../i18n'
import { temporadaMapping } from '../../../specific/helpers/gtmReporter'
import { getImages, ImageNode } from '../../utils/imagesS3'
import route from '../../utils/route'
import styles from './marca.module.scss'
import withMarcas, { Marca } from '../../components/withMarcas'
import { Helmet } from 'react-helmet'
import _ from 'lodash'
import ModelosDescatalogados from './inc/ModelosDescatalogados'
import CardTireModel from '../../components/CardTireModel/CardTireModel'
import MarcaCocheMotoSelector from '../../components/LandingMarca/marcaCocheMotoSelector'
import {
  getNeumaticosActivosAndDescatalogados,
  getNeumaticosTopVentas,
  getTiposVehiculo,
  s3Url,
  toTopVentasMapper,
} from '../../components/LandingMarca/utils'
import { FEATURE_FLAGS, isFeatureEnabled } from '../../utils/featureFlag'
import SeccionNeumatico from '../../../shared/components/SeccionNeumaticos/SeccionNeumatico'

const website = process.env.GATSBY_WEB

interface Props extends PageProps<Queries.LandingMarcaPageQuery> {
  pageContext: {
    pageUrl: string
  }
  dispatch: (action: any) => void
  skipInfo: InSkipInfo
  marcas: Marca[]
}

const LandingMarcas = ({
  dispatch,
  skipInfo,
  pageContext,
  marcas,
  data: {
    marcaInfo,
    modeloInfo,
    seoData,
    banners_es_desktop,
    banners_ca_desktop,
    banners_pt_desktop,
    banners_es_mobile,
    banners_ca_mobile,
    banners_pt_mobile,
  },
}: Props) => {
  const { selectorState } = useContext(AppContext)
  useSelectedMenu(MenuPrincipal.NEUMATICOS)
  const { marca, descripcion, banners, logoMarca, mas_info, coche, moto } =
    marcaInfo

  const { modelos } = modeloInfo
  const { pageUrl } = pageContext

  const { neumaticosActivos, neumaticosDescatalogados } =
    getNeumaticosActivosAndDescatalogados(modelos)

  const bannersDesktop = {
    es: banners_es_desktop.nodes,
    ca: banners_ca_desktop.nodes,
    pt: banners_pt_desktop.nodes,
  }

  const bannersMobile = {
    es: banners_es_mobile.nodes,
    ca: banners_ca_mobile.nodes,
    pt: banners_pt_mobile.nodes,
  }

  const bannerImagesDesktop = getImages(
    bannersDesktop[locale].map((banner: ImageNode) => banner),
    locale
  )
  const bannerImagesMobile = getImages(
    bannersMobile[locale].map((banner: ImageNode) => banner),
    locale
  )

  let bannerImages = []
  if (website === 'rodi') {
    bannerImages = banners.map((banner) => {
      return {
        Key: '',
        url: banner.url,
        link: '',
        alt: t('ficha-marca.neumatico', { marca }),
      }
    })
  }
  useEffect(() => {
    dispatch(
      reset({
        keepValues: false,
        origen:
          typeof document !== 'undefined' ? document.location.pathname : '/',
        tipoVehiculo: null,
        temporada: {
          selectable: process.env.GATSBY_WEB === 'rodi',
          value: 'verano',
        },
        marca: marcaInfo.id_site_marca_neumatico,
      })
    )
  }, [])

  const tiposVehiculo = getTiposVehiculo(neumaticosActivos)

  // Sort tipoVehiculo as client demands: Turismo, 4x4, Furgoneta
  if (tiposVehiculo.length === 3) {
    const aux = tiposVehiculo.splice(1).reverse()
    tiposVehiculo.push(...aux)
  }

  const neumaticosTopVentas = _.uniqBy(
    getNeumaticosTopVentas(neumaticosActivos),
    'slug'
  )

  const logoUrl = require(`!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../assets/images/logo_marcas/${logoMarca}.svg`)

  return (
    <Layout
      selector={false}
      seoData={seoData}
      breadCrumItems={[
        { display: t('bread_crumb.inicio'), link: route('index') },
        {
          display: t('bread_crumb.neumaticos'),
          link: route('neumaticos.index'),
        },
        {
          display: marca,
        },
        {
          display: t('cita_previa.servicios.coche'),
        },
      ]}>
      <Helmet>
        <link rel="preload" href={logoUrl.default} />
        {['rodi', 'elpaso'].includes(process.env.GATSBY_WEB) ? (
          <meta
            property="og:image"
            content={`${s3Url}/marca/${marca.toLowerCase()}/neumaticos-${marca.toLowerCase()}.jpg `}
          />
        ) : null}
      </Helmet>
      <section className={styles.ficha_marca}>
        <div className={styles.fichaMarcaContent}>
          <Title text={t('ficha-marca.neumatico', { marca })}>
            <img
              src={logoUrl.default}
              alt={marca}
              className={styles.logo}
              loading="eager"
            />
          </Title>
          {coche && moto && isFeatureEnabled(FEATURE_FLAGS.NEUMATICOS_MOTO) ? (
            <MarcaCocheMotoSelector marca={marca} />
          ) : null}

          <ResponsiveSliderBanner
            sizesVariant="banner_neumaticos"
            desktopImages={
              bannerImagesDesktop.length ? bannerImagesDesktop : bannerImages
            }
            mobileImages={
              bannerImagesMobile.length ? bannerImagesMobile : bannerImages
            }
          />
          <section className={styles.descripcion_marca}>{descripcion}</section>

          <div className={styles.filter_container}>
            <h2 className={styles.title}>
              {t('ficha-marca.encuentra', { marca })}
            </h2>
            <div className={styles.selector_mobile}>
              <div className={styles.image_cont}>
                <NeumaticoMobileDefaultValues />
              </div>
              <button
                className={styles.next_button}
                onClick={() => {
                  const marca = marcas.find(
                    (marca) => marca.id === selectorState.marca
                  )
                  dataLayer.push({
                    event: 'buscar_neumaticos',
                    action: 'marca',
                    label: 'mobile',
                    tipo_neumaticos:
                      temporadaMapping[selectorState.temporada.value],
                    marca: marca ? marca.label : 'all',
                  })
                  const next = findNext(Step.INITIAL, 'mobile', null)
                  navigate(next.routes.mobile)
                }}>
                {t('home.mobile-selector-button')}
              </button>
            </div>
            <div className={styles.selector_tablet_or_more}>
              <SelectorContainer showMarcas={false} origin={pageUrl}>
                {({ ...props }) => (
                  <SelectorForm
                    fromModal={false}
                    handleSubmit={() => {
                      const marca = marcas.find(
                        (marca) => marca.id === selectorState.marca
                      )
                      dataLayer.push({
                        event: 'buscar_neumaticos',
                        action: 'marca',
                        label: 'desktop',
                        tipo_neumaticos:
                          temporadaMapping[selectorState.temporada.value],
                        runflat: selectorState.runflat.value,
                        marca: marca ? marca.label : 'all',
                      })
                      const next = findNext(
                        Step.SELECT_MARCAS,
                        'desktop',
                        skipInfo
                      )
                      navigate(next.routes.desktop)
                    }}
                    {...props}
                  />
                )}
              </SelectorContainer>
            </div>
          </div>

          {!!neumaticosTopVentas.length && (
            <section className={styles.productos}>
              <h2 className={styles.title_gama}>
                {t('ficha-marca.neumaticos_top_ventas', { marca })}
              </h2>
              <CardTireModel
                marca={marca}
                logoMarca={logoMarca}
                neumaticos={neumaticosTopVentas.map(toTopVentasMapper)}
                title={''}
              />
            </section>
          )}

          <h2 className={styles.title_gama}>
            {t('ficha-marca.gama', { marca })}
          </h2>
          <section className={styles.productos}>
            {neumaticosActivos.length > 0 && (
              <SeccionNeumatico
                marca={marca}
                logoMarca={logoMarca}
                neumaticos={neumaticosActivos}></SeccionNeumatico>
            )}
          </section>
          <div
            className={styles.descripcion_marca}
            dangerouslySetInnerHTML={{ __html: mas_info }}></div>
          {neumaticosDescatalogados.length ? (
            <section className={styles.descatalogados_section}>
              <div className={styles.descatalogados_content}>
                <ModelosDescatalogados
                  title={t('ficha-marca.descatalogados_title', { marca })}
                  neumaticos={neumaticosDescatalogados}
                  marca={marca}
                />
              </div>
            </section>
          ) : null}
        </div>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query LandingMarcaPage(
    $idMarca: Int!
    $url: String!
    $banners_es_desktop: String!
    $banners_ca_desktop: String!
    $banners_es_mobile: String!
    $banners_ca_mobile: String!
    $banners_pt_desktop: String
    $banners_pt_mobile: String
  ) {
    marcaInfo: neumaticosMarca(id_site_marca_neumatico: { eq: $idMarca }) {
      ...marcaInfo
    }
    modeloInfo: neumaticosMarca(id_site_marca_neumatico: { eq: $idMarca }) {
      ...modelosCocheMarca
    }
    banners_ca_desktop: allS3Asset(
      filter: { Key: { glob: $banners_ca_desktop } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_ca_mobile: allS3Asset(
      filter: { Key: { glob: $banners_ca_mobile } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_es_desktop: allS3Asset(
      filter: { Key: { glob: $banners_es_desktop } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_es_mobile: allS3Asset(
      filter: { Key: { glob: $banners_es_mobile } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_pt_desktop: allS3Asset(
      filter: { Key: { glob: $banners_pt_desktop } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_pt_mobile: allS3Asset(
      filter: { Key: { glob: $banners_pt_mobile } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
  }
`

export default connect((state: IAppState) => ({
  skipInfo: selectSkipInfo(state),
}))(withMarcas(LandingMarcas))
