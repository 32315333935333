import React from 'react'
import { Link } from 'gatsby'
import styles from './landingMarca.module.scss'
import Moto from '../../assets/images/family_icons/icon_moto.svg'
import route from '../../utils/route'
import {
  NEUMATICOS_MARCA_MOTO_ROUTE_ID,
  NEUMATICOS_MARCA_ROUTE_ID,
} from './utils'
import { t } from '../../i18n'
import Coche from '../../assets/images/family_icons/icon_coche.svg'

interface Props {
  marca: string
}
const MarcaCocheMotoSelector = ({ marca }: Props) => {
  const tabsArray = [
    {
      tab: (
        <React.Fragment>
          <Coche />
          <span>{t('cita_previa.servicios.coche')}</span>
        </React.Fragment>
      ),
      route: route(NEUMATICOS_MARCA_ROUTE_ID, {
        marca: marca.toLowerCase(),
      }),
    },
    {
      tab: (
        <React.Fragment>
          <Moto className={styles.icon_moto} />
          <span>{t('cita_previa.servicios.moto')}</span>
        </React.Fragment>
      ),
      route: route(NEUMATICOS_MARCA_MOTO_ROUTE_ID, {
        marca: marca.toLowerCase(),
      }),
    },
  ]

  return (
    <div className={styles.marcaCocheMotoSelector_flexTabsContainer}>
      {tabsArray.map(({ tab, route }) => (
        <Link
          to={route}
          key={route}
          activeClassName={`${styles.tab_active} ${styles.tab_title_with_logo}`}>
          {tab}
        </Link>
      ))}
    </div>
  )
}

export default MarcaCocheMotoSelector
