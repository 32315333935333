import { getImages } from '../../utils/imagesS3'
import { locale } from '../../i18n'
import _ from 'lodash'

export const logoUrl = (logoMarca: string) => {
  return require(`!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../assets/images/logo_marcas/${logoMarca}.svg`)
}

export const s3Url =
  process.env.GATSBY_WEB === 'elpaso'
    ? 'https://media.elpaso2000.com'
    : 'https://media.rodi.es'

export const marcaBannerImages = (images) => getImages(images, locale)

export const getNeumaticosActivosAndDescatalogados = (neumaticos) => {
  const { neumaticosActivos, neumaticosDescatalogados } = neumaticos.reduce(
    (acc, neumatico) => {
      if (neumatico.descatalogado) {
        if (
          !acc.neumaticosDescatalogados.some((n) => n.name === neumatico.name)
        )
          acc.neumaticosDescatalogados.push(neumatico)
      } else {
        acc.neumaticosActivos.push(neumatico)
      }
      return acc
    },
    { neumaticosActivos: [], neumaticosDescatalogados: [] }
  )

  return { neumaticosActivos, neumaticosDescatalogados }
}

export const getNeumaticosTopVentas = (neumaticosActivos) =>
  _.uniqBy(
    neumaticosActivos.filter((neum) => {
      return neum.top_ventas
    }),
    'slug'
  )

export const getTiposVehiculo = (neumaticos) => {
  const tiposVehiculo = []
  neumaticos.forEach((neumatico) => {
    const { tipovehiculo } = neumatico
    if (tiposVehiculo.indexOf(tipovehiculo) < 0) {
      tiposVehiculo.push(tipovehiculo)
    }
  })

  return tiposVehiculo
}

export const motoModelosGamaAndTipoReducer = (modelos) => {
  return modelos.reduce((acc, curr) => {
    const { gama } = curr
    const existingEntry = acc.find((entry) => entry.gama === gama)

    if (existingEntry) {
      existingEntry.modelos.push(curr)
    } else {
      acc.push({
        gama,
        modelos: [curr],
      })
    }

    return acc
  }, [])
}

export const reducedMotoModelosSorter = (reducedModelos) => {
  return reducedModelos.sort((a, b) => {
    const gamaTipoA = `${a.gama}`
    const gamaTipoB = `${b.gama}`

    return gamaTipoA.localeCompare(gamaTipoB)
  })
}

export const mapModelosMotoWithUrlImage = (modelos) => {
  return modelos.map((modelo) => {
    return { ...modelo, urlimagen: modelo.imagenes[1].url }
  })
}

export const NEUMATICOS_MARCA_MOTO_ROUTE_ID = 'neumaticos.marca-moto'
export const NEUMATICOS_MARCA_ROUTE_ID = 'neumaticos.marca'

export const isMarcaMotoRouteId = (routeId: string) =>
  routeId === NEUMATICOS_MARCA_MOTO_ROUTE_ID
export const isMarcaRouteId = (routeId: string) =>
  routeId === NEUMATICOS_MARCA_ROUTE_ID

export const toTopVentasMapper = (neumatico) => ({
  name: neumatico.name,
  slug: neumatico.slug,
  urlimagen: neumatico.urlimagen,
})
