import { Link, navigate } from 'gatsby'
import React from 'react'
import { getDefaultNeumaticoUrl } from '../../utils/getDefaultNeumaticoImageUrl'
import Button from '../buttons/Button'
import WhiteBoxContainer from '../WhiteBoxContainer'
import styles from './index.module.scss'

interface Props {
  children: JSX.Element
  text: string
  link: string
  image: string
  buttonText: string
}

export default function BoxImageTitle({
  children,
  text,
  link,
  image,
  buttonText,
}: Props) {
  return (
    <div className={styles.linkBox}>
      <WhiteBoxContainer>
        <div className={styles.upperBox}>
          {children && <div className={styles.logoContainer}>{children}</div>}
          <div className={styles.image}>
            <img
              className={styles.imgTyre}
              src={image || getDefaultNeumaticoUrl()}
              alt={text}
              loading="lazy"
              onClick={() => navigate(link)}
            />
          </div>
        </div>
        <div className={styles.lowerBox}>
          <div className={styles.text}>
            <Link to={link} className={styles.true_link}>
              {text}
            </Link>
          </div>
          <Button buttonClass={'link_button'} onClick={() => navigate(link)}>
            {buttonText}
          </Button>
        </div>
      </WhiteBoxContainer>
    </div>
  )
}
