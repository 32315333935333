import styles from '../marca.module.scss'
import React from 'react'
import route from '../../../utils/route'
import { NeumaticoData } from '../marca'

interface Props {
  title: string
  neumaticos: NeumaticoData[]
  marca: string
}
const ModelosDescatalogados = ({ title, neumaticos, marca }: Props) => {
  return (
    <React.Fragment>
      <p className={styles.descatalogados_title}>{title}</p>
      <ul className={styles.descatalogados_list}>
        {neumaticos.map((neumatico) => (
          <li key={`descatalogados-${neumatico.slug}`}>
            <a
              title={`${marca} ${neumatico.modelo ?? neumatico.marca_modelo}`}
              href={route('neumaticos.modelo', {
                marca: marca.toLowerCase(),
                modelo: neumatico.slug.toLowerCase(),
              })}>{`${marca} ${neumatico.name}`}</a>
          </li>
        ))}
      </ul>
    </React.Fragment>
  )
}

export default ModelosDescatalogados
