import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import Neumatico from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../assets/neumatico-selector.png'
import { t } from '../../../../../common/i18n'
import StandardModal from '../../../modal/standardModal/StandardModal'
import styles from './modalInfoMedidas.module.scss'
import dataLayer from '../../../../helpers/functions/dataLayer'

interface Props {
  showModalMoreInfo: boolean
  setShowModalMoreInfo: (active: boolean) => void
}
const ModalInfoMedidas = ({
  showModalMoreInfo,
  setShowModalMoreInfo,
}: Props) => {
  useEffect(() => {
    if (showModalMoreInfo) {
      dataLayer.push({
        event: 'medidas',
        action: 'click',
        label: 'ayuda',
      })
    }
  }, [showModalMoreInfo])

  return (
    <StandardModal
      title={t('selector-desktop.modal_title')}
      closeModal={() => setShowModalMoreInfo(false)}
      showModal={showModalMoreInfo}>
      <div className={styles.modalNoInfoMobile_wrapper}>
        <p className={styles.description_info}>
          <FormattedMessage id="modal_como_obtener_medidas.descripcion_medidas" />
        </p>
        <div className={styles.header}>
          <div className={styles.neumatico_wrapper}>
            <img
              src={Neumatico}
              alt="Neumático genérico"
              className={styles.neumatico}
              width={450}
              height={133}
            />
            <div className={styles.arrowAncho}>
              <div className={styles.firstPart} />
              <div className={styles.secondPart} />
            </div>
            <div className={styles.serieArrow}></div>
            <div className={styles.llantaArrow}>
              <div className={styles.firstPart} />
              <div className={styles.secondPart} />
            </div>
            <div className={styles.icArrow}>
              <div className={styles.firstPart} />
              <div className={styles.secondPart} />
            </div>
            <div className={styles.cvArrow} />
            <span className={styles.anchoTitle}>
              <FormattedMessage id="modal_como_obtener_medidas.ancho" />
            </span>
            <span className={styles.anchoText}> 205 </span>
            <span className={styles.line}> / </span>
            <span className={styles.serieTitle}>
              <FormattedMessage id="modal_como_obtener_medidas.serie" />
            </span>
            <span className={styles.serieText}> 55 </span>
            <span className={styles.llantaTitle}>
              <FormattedMessage id="modal_como_obtener_medidas.llanta" />
            </span>
            <span className={styles.llantaText}> R16</span>
            <span className={styles.icTitle}>
              <FormattedMessage id="modal_como_obtener_medidas.ic" />
            </span>
            <span className={styles.icText}>91</span>
            <span className={styles.cvTitle}>
              <FormattedMessage id="modal_como_obtener_medidas.cv" />
            </span>
            <span className={styles.cvText}> V </span>
          </div>
        </div>
        <div className={styles.description}>
          <div className={styles.description_item}>
            <span className={styles.description_title}>
              <FormattedMessage id="modal_como_obtener_medidas.ancho_title" />
            </span>
            <p className={styles.description_text}>
              <FormattedMessage id="modal_como_obtener_medidas.ancho_description" />
            </p>
          </div>

          <div className={styles.description_item}>
            <span className={styles.description_title}>
              <FormattedMessage id="modal_como_obtener_medidas.serie_title" />
            </span>
            <p className={styles.description_text}>
              <FormattedMessage id="modal_como_obtener_medidas.serie_description" />
            </p>
          </div>

          <div className={styles.description_item}>
            <span className={styles.description_title}>
              <FormattedMessage id="modal_como_obtener_medidas.diametro_title" />
            </span>
            <p className={styles.description_text}>
              <FormattedMessage id="modal_como_obtener_medidas.diametro_description" />
            </p>
          </div>

          <div className={styles.description_item}>
            <span className={styles.description_title}>
              <FormattedMessage id="modal_como_obtener_medidas.ic_title" />
            </span>
            <p className={styles.description_text}>
              <FormattedMessage id="modal_como_obtener_medidas.ic_description" />
            </p>
          </div>

          <div className={styles.description_item}>
            <span className={styles.description_title}>
              <FormattedMessage id="modal_como_obtener_medidas.cv_title" />
            </span>
            <p className={styles.description_text}>
              <FormattedMessage id="modal_como_obtener_medidas.cv_description" />
            </p>
          </div>
        </div>
      </div>
    </StandardModal>
  )
}

export default ModalInfoMedidas
